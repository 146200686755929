var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "templateCertificate" },
    [
      _vm.personIds && _vm.personIds.length > 0
        ? _c("Card", { staticClass: "iCard" }, [
            _c("iframe", {
              staticClass: "iFame",
              attrs: { src: _vm.previewHtmlUrl, frameborder: "0" },
            }),
          ])
        : _c(
            "Card",
            [
              _c("no-data", {
                staticStyle: {
                  height: "100%",
                  overflow: "hidden",
                  display: "flex",
                  "flex-direction": "row",
                  "justify-content": "center",
                },
                attrs: { width: "150" },
              }),
            ],
            1
          ),
      _c(
        "Spin",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          attrs: { fix: "" },
        },
        [
          _c("div", { staticClass: "demo-spin-icon-load" }, [
            _c("i", { staticClass: "iconfont icon-loader--line" }),
          ]),
          _c("div", { staticStyle: { "font-size": "16px" } }, [
            _vm._v("Loading..."),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }